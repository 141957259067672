@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button,
p,
h1,
h2,
h3,
h4,
h5,
h6,
input,
body,
html,
header,
section,
main,
span,
ul,
li {
  padding: 0;
  margin: 0;
  border: 0;
}
button:before,
p:before,
h1:before,
h2:before,
h3:before,
h4:before,
h5:before,
h6:before,
input:before,
body:before,
html:before,
header:before,
section:before,
main:before,
span:before,
button:after,
p:after,
h1:after,
h2:after,
h3:after,
h4:after,
h5:after,
h6:after,
input:after,
body:after,
html:after,
header:after,
section:after,
main:after,
span:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}
nav,
footer,
header,
aside {
  display: block;
}
html,
body {
  width: 100%;
  height: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
}
